import { useField, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import Icon, { IconName } from '../../base/Icon';
import Text from '../../base/Text';
import DropdownMenu, { DropdownMenuProps } from '../../DropdownMenu';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import Button from '../../base/Button';

interface SelectOption {
  value: string;
  label: string;
  icon?: IconName | React.ReactElement;
  description?: string;
  disabled?: boolean;
}

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  options: SelectOption[];
  help?: string;
  default_value?: string;
  type?: string;
  placeholder?: string;
  auto_complete?: string;
  minimal?: boolean;
  required?: boolean;
  block?: boolean;
  search?: boolean;
  search_placeholder?: string;
  search_term?: string;
  setSearchTerm?: (value: string) => void;
  search_is_loading?: boolean;
  separator?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  dropdown?: Partial<DropdownMenuProps>;
  borderless?: boolean;
  sublte_error?: boolean;
}

const StyledField = styled(Div)<{ minimal?: boolean; large?: boolean; block?: boolean }>(
  ({ block }) => css`
    width: fit-content;

    ${block &&
    css`
      width: 100%;
    `};

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
);

const StyledSelect = styled(Div)<{
  error: boolean;
  subtle_error?: boolean;
  has_value: boolean;
  has_value_with_icon: boolean;
  borderless?: boolean;
}>(
  ({ theme, error, subtle_error = false, has_value, has_value_with_icon, borderless }) => css`
    position: relative;
    width: 100%;

    ${!borderless &&
    css`
      &:focus-within {
        outline: none;
        &::after {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large};
        }
      }
    `}

    button {
      padding-right: ${({ theme }) => theme.spacing(9)};
      padding-left: ${has_value_with_icon ? theme.spacing(9) : theme.spacing(3)};
      background-color: transparent !important;

      ${error &&
      !subtle_error &&
      css`
        color: ${theme.colors.on.neutral.danger}};
        button {
          border-color: ${theme.colors.outline.danger};
          background-color: ${theme.colors.surface.container.danger};
        }
      `}
    }

    span {
      pointer-events: none;
    }
  `,
);

const SelectInput: React.FC<Props> = ({
  label,
  name,
  default_value,
  auto_complete,
  required,
  help,
  minimal,
  options,
  block = false,
  search = false,
  search_placeholder,
  search_term,
  setSearchTerm,
  search_is_loading,
  separator = true,
  dropdown = {},
  disabled: _disabled,
  placeholder,
  onChange,
  borderless,
  sublte_error,
  ...props
}) => {
  const disabled = usePermissionAwareDisabled(_disabled);
  const [field, meta, { setValue, setTouched }] = useField({
    name,
  });

  useEffect(() => {
    if (default_value) {
      setValue(default_value);
    }
  }, [default_value, setValue]);

  let error = meta.error;
  const touched = meta.touched;

  // Empty value don't get validated by default by Formik for Select. Workarround..
  if (!error && required && !field.value) {
    error = 'Required';
  }

  const { submitCount } = useFormikContext();
  const has_error = (touched || submitCount > 0) && !!error;
  const select_ref = useRef<HTMLButtonElement>(null);
  const search_ref = useRef<HTMLInputElement>(null);
  const selected_option = !!field.value && options.find((o) => o.value === field.value);
  return (
    <Div m={{ bottom: 4 }} {...props}>
      <StyledField minimal={minimal} large block={block}>
        {label && (
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <label htmlFor={name}>
              <Text size="s" subtitle>
                {label}{' '}
                {required && (
                  <Text danger as="span">
                    *
                  </Text>
                )}
              </Text>
            </label>
          </Div>
        )}
        <DropdownMenu
          p={0}
          parent_width
          search_ref={search_ref}
          search={search}
          search_placeholder={search_placeholder}
          search_term={search_term}
          setSearchTerm={setSearchTerm}
          search_is_loading={search_is_loading}
          separator={separator}
          placement={'bottom'}
          onToggle={(opened) => {
            if (!opened && search) {
              setTouched(true);
            }
          }}
          renderToggle={(opened, toggle) => (
            <StyledSelect
              borderless={borderless}
              error={has_error}
              subtle_error={sublte_error}
              has_value={!!field.value}
              has_value_with_icon={!!selected_option && !!selected_option?.icon}>
              {!!selected_option && selected_option?.icon && (
                <Icon
                  icon={selected_option.icon as IconName}
                  right={3}
                  padding={{ t: 2 }}
                  style={{ position: 'absolute', cursor: 'pointer' }}
                  onMouseDown={() => {
                    toggle(!opened);
                    setTimeout(() => search_ref.current?.focus(), 0);
                  }}
                />
              )}
              <Button
                {...field}
                value={field.value || ''}
                autoComplete={auto_complete}
                required={required}
                disabled={disabled}
                ref={select_ref}
                outline
                w={100}
                minimal
                onMouseDown={() => {
                  toggle(!opened);
                  setTimeout(() => search_ref.current?.focus(), 0);
                }}>
                {options?.find((o) => o?.value === select_ref?.current?.value)?.label ||
                  placeholder ||
                  'Select one...'}
              </Button>
              <Icon
                icon="expand_all"
                muted={!has_error}
                danger={has_error && !sublte_error}
                right={-7}
                padding={{ t: 2 }}
                style={{ position: 'absolute', cursor: 'pointer' }}
              />
            </StyledSelect>
          )}
          {...dropdown}
          options={options.map((option) => ({
            label: option.label,
            icon: option.icon,
            description: option.description,
            disabled: option.disabled,
            onClick: () => {
              setValue(option.value);
              onChange && onChange();
            },
          }))}
        />
      </StyledField>
      {has_error && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" danger>
          <Icon icon="info" left={1} />
          {error}
        </Text>
      )}
      {help && !has_error && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
          <Icon icon="info" left={1} />
          {help}
        </Text>
      )}
    </Div>
  );
};

export default SelectInput;
